export const COMPARISON_TYPES = {
  previousPeriod: 'previousPeriod',
  previousYear: 'previousYear',
  previousPeriods: 'previousPeriods'
}

export const DATE_RANGES = {
  allTime: 'allTime',
  today: 'today',
  yesterday: 'yesterday',

  thisWeek: 'thisWeek',
  lastWeek: 'lastWeek',

  last7Days: 'last7Days',
  last14Days: 'last14Days',
  last30Days: 'last30Days',
  last90Days: 'last90Days',

  thisMonth: 'thisMonth',
  lastMonth: 'lastMonth',

  last12Months: 'last12Months',

  thisYear: 'thisYear',
  lastYear: 'lastYear',

  thisQuarter: 'thisQuarter',
  lastQuarter: 'lastQuarter'
}
